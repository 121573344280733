.RegionPageList {
    padding: 30px 0 10px;
    width: 90%;
    margin: 10px auto;

    .headings {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 40px 0;
        /* margin: 15px 0; */
        width: 100%;
        color: #292B2E;
        letter-spacing: 1px;

        h1 {
            font-size: 45px;
            font-weight: 500;
        }

        h2 {
            font-size: 35px;
            font-weight: 400;
        }
    }

    .heading-text {
        width: 100%;
        font-size: 28px;
        font-weight: 500;
        color: #292B2E;
        text-align: center;
    }

    .AllCountries {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        margin-top: 2px;

        .CountryNameCard {
            flex: 1 1 23%;
            /* Automatically adjusts based on available space */
            display: flex;
            flex-direction: column;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
            border-radius: 7px 30px 0 0;
            cursor: pointer;
            margin-top: 35px;
            overflow: hidden;
            background-color: #fff;

            h3 {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                background: linear-gradient(105deg, #5CA7E0 69%, #75BFEC 69%);
                padding: 10px;

                .name-part1 {
                    color: #042941;
                    font-size: 20px;
                    font-weight: 900;
                }

                .name-part2 {
                    color: #fff;
                    padding: 3px;
                    font-size: 18px;
                    border-radius: 5px;
                }
            }

            .continenet_img {
                padding: 20px;
                height: 185px;
                object-fit: contain;
                filter: drop-shadow(0px 0px 5px rgb(26, 27, 27));
            }

            a {
                font-size: 14px;
                font-weight: 400;
                color: #3936e6;
                padding: 5px 15px;

                &:hover {
                    transform: translateX(12px);
                    transition: 0.4s ease-in-out;
                    color: #007BFF;
                }
            }

            .view-all-plans {
                font-size: 18px;
                font-weight: 500;
                padding: 10px;
                background-color: #E5F6FD;
                border: 2px solid #E5F6FD;
                display: flex;
                justify-content: center;
                color: #007BFF;
                transition: color 0.3s ease;

                &:hover {
                    color: #1d1d1f;
                }
            }

            &:hover {
                transform: translateY(-10px);
                transition: 0.4s ease-in-out;
                box-shadow: 0px 0px 10px rgb(75, 71, 71);
            }
        }
    }

    .AllRegions {
        display: none;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 30px;
        margin-top: 20px;

        .RegionCard {
            display: flex;
            align-items: center;
            padding: 10px;
            justify-content: space-between;
            box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
            border-radius: 5px;
            cursor: pointer;
            background: linear-gradient(105deg, #5CA7E0 69%, #75BFEC 69%);
            transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 0 10px rgb(75, 71, 71);
            }

            .Names {
                display: flex;
                flex-direction: column;
                gap: 10px;

                h4 {
                    font-size: 1.25rem;
                    color: #042941;
                    font-weight: 700;
                }

                p {
                    color: #291717;
                    background-color: #fff;
                    padding: 3px 8px;
                    font-size: 0.75rem;
                    border-radius: 7px;
                    transition: color 0.3s ease;

                    &:hover {
                        color: rgb(57, 138, 245);
                    }
                }
            }
        }
    }
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .RegionPageList .AllCountries .CountryNameCard {
        flex: 1 1 30%;
    }
}

@media screen and (max-width: 992px) {
    .RegionPageList .headings {
        padding: 25px 0;

        h1 {
            font-size: 35px;
        }

        h2 {
            font-size: 22px;
        }
    }

    .RegionPageList .AllCountries .CountryNameCard {
        flex: 1 1 46%;
    }
}

@media screen and (max-width: 682px) {
    .RegionPageList {
        padding: 10px 0 10px;
    }

    .RegionPageList .headings {
        padding: 15px 0;
        text-align: center;

        h1 {
            font-size: 30px;
        }

        h2 {
            font-size: 18px;
        }
    }

    .RegionPageList .AllCountries {
        display: none;
    }

    .RegionPageList .AllRegions {
        display: grid;

        grid-template-columns: repeat(1 1fr);
        gap: 10px;

        .RegionCard {
            padding: 12px 10px;
            margin-bottom: 5px;

            .Names {
                width: 80%;
                align-items: center;
                row-gap: 7px;

                h4 {
                    font-size: 1.35rem;
                }

                p {
                    font-size: .9rem;
                }
            }
        }

    }
}