.HomePage {
    .Banner {
        min-height: 80vh;
        width: 90%;
        margin: 0px auto;
        display: grid;
        grid-template-columns: 50% 50%;
        /* border: 3px red solid; */

        .s1 {
            display: flex;
            row-gap: 6%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;

            h1 {
                font-size: 60px;
                color: #292B2E;
                letter-spacing: 1px;
            }

            p {
                width: 80%;
                font-size: 22px;
                color: #292B2E;
                font-weight: 400;
                letter-spacing: 1px;
            }

            .s1s1 {
                width: 100%;
                display: flex;
                justify-content: space-between;

                img {
                    width: 200px;
                    height: 78px;
                }
            }

            a {
                color: #39aee0;
                font-size: 24px;
                text-decoration: none;
                display: flex;
                align-items: center;
                width: fit-content;

                #searchIcon {
                    font-size: 35px;
                    margin-right: 6px;
                }
            }

            a:hover {
                transform: translateX(10px);
                transition: all .4s ease-in-out;
                color: #0b79a8;
            }
        }

        .s2 {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(../../Assets//backgroundBanner.png);
            background-position: right;
            background-repeat: no-repeat;
            background-size: 95%;
            z-index: -10;

            #RatingImage {
                display: none;
            }

            #ManImage {
                width: 95%;
                height: auto;
            }
        }
    }
}

.providers {
    width: 90%;
    margin: 80px auto;
    color: #292B2E;
    /* border: 3px purple solid; */

    h2 {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        color: #292b2e;
    }

    .providersLogo {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        margin: 30px 0px;

        #companiesLogos {
            width: 12%;
            height: auto;
            filter: drop-shadow(0px 0px 3px #e3e1e1);
        }
    }
}

@media screen and (max-width: 740px) {
    .HomePage {
        .Banner {
            min-height: fit-content;
            display: flex;
            flex-direction: column;
            width: 100%;

            .s1 {
                width: 100%;
                margin-bottom: 20px;
                padding: 20px;


                h1 {
                    font-size: 42px;
                    letter-spacing: 1px;
                    line-height: 1;
                    margin: 5% 0 5% 0;
                    text-align: center;
                    width: 100%;
                }

                p {
                    width: 100%;
                    font-size: 18px;
                    letter-spacing: 1px;
                    line-height: 1.2;
                    text-align: center;
                }

                .s1-para {
                    margin-top: 2%;
                    font-weight: 500;
                    font-size: 19px;
                }


                .s1s1 {
                    display: none;
                }

                a {
                    font-size: 20px;
                    justify-content: center;

                    #searchIcon {
                        font-size: 30px;
                    }
                }
            }

            .s2 {
                width: 100%;
                background-size: cover;
                position: relative;

                #RatingImage {
                    position: absolute;
                    display: block;
                    top: 5%;
                    left: 8%;
                    width: 28%;
                    height: auto;
                }

                #ManImage {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}






/* Responsive Styles for providers section ___________________*/
@media screen and (max-width: 1200px) {
    .providers {
        .providersLogo {
            #companiesLogos {
                width: 17%;
            }
        }
    }

    .WhySection {
        .CardsBox {
            .card {
                width: 45%;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .providers {
        .providersLogo {

            #companiesLogos {
                width: 25%;
                margin: 10px 30px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .providers {
        h2 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            width: 70%;
            margin: 0px auto;
        }

        .providersLogo {
            margin: 20px 0px;

            #companiesLogos {
                width: 30%;
                margin: 10px 30px;
            }
        }
    }
}


.SessionTimeOutModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    height: 60%;
    outline: 0px;
    border-radius: 20px;
    background-color: #00A1E4;
    display: flex;
    align-items: center;
    justify-content: center;

    #Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 14px;
        width: 100%;
        /* border: 2px solid green; */

        img {
            align-items: center;
            width: 40%;
            height: 40%;
        }

        h2 {
            font-size: 20px;
            color: white;
        }
    }
}


@media (max-width: 888px) {
    .SessionTimeOutModal {
        width: 80%;

        #Content {
            row-gap: 14px;

            img {
                width: 40%;
                height: auto;
            }
    

            h2 {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 500px) {
    .SessionTimeOutModal {
        width: 86%;

        #Content {
            row-gap: 10px;

            img {
                width: 60%;
            }
    

            h2 {
                font-size: 16px;
                text-align: center;
            }
        }
    }
}


@media (max-width: 400px) {
    .SessionTimeOutModal {
        width: 86%;

        #Content {
            row-gap: 10px;

            img {
                width: 55%;
            }
    

            h2 {
                font-size: 13px;
                text-align: center;
            }
        }
    }
}