.Business {
    .Banner {
        height: 600px;
        width: 90%;
        margin: 0px auto;
        display: grid;
        grid-template-columns: 50% 50%;


        .s1 {
            display: flex;
            row-gap: 6%;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            margin-top: 10%;

            h1 {
                font-size: 60px;
                color: #292B2E;
                letter-spacing: 1px;
            }

            p {
                width: 80%;
                font-size: 28px;
                color: #292B2E;
                font-weight: 400;
                letter-spacing: 1px;

            }

            .contact_button {
                font-size: 15px;
                color: #FFF;
                background-color: #00a1e4;
            }

            .contact_button:hover {
                transform: translateY(-2px);
                transition: all .4s ease-in-out;
                background-color: #0d5574;
            }


        }

        .b-img {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(../../Assets/Business_section.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -10;


        }
    }
}


@media screen and (max-width: 680px) {
    .Business {
        .Banner {
            height: 700px;
            grid-template-columns: 100%;
            text-align: center;

            .s1 {
                align-items: center;
                height: 300px;

                h1 {
                    font-size: 40px;
                }

                p {
                    width: 100%;
                    font-size: 18px;
                }
            }

            .b-img {
                background-size: contain;
                width: 100%;
                height: 300px;
            }
        }
    }
}