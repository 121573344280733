.payment_failed {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px;

    h2 {
        color: #131f32;
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;


        .error-icon {
            color: red;
            width: 40px;
            height: 40px;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    p {
        color: #4b64ff;
        text-align: center;
        font-size: 19px;
        width: 90%;
    }

    .retry-button {
        color: #ffffff;
        background-color: #00a1e4;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 8px;
        width: 70%;

        &:hover {
            background-color: #007bb2;
        }
    }
}