.slider-container {
    width: 90%;
    margin: 0 auto;
    padding: 40px 0;
    margin-bottom: 50px;

    .heading {
        font-size: 28px;
        font-weight: 500;
        width: 100%;
        color: #292B2E;
        text-align: center;
    }

    @media screen and (max-width: 900px) {
        width: 80%;
        padding: 20px 0;
        margin-bottom: 20px;

        .heading {
            text-align: center;
        }
    }
}

.card {
    border-radius: 10px;
    padding: 20px;
    text-align: center;

    h3 {
        margin: 0;
        font-size: 1.5em;
    }

    p {
        margin: 10px 0 0;
        font-size: 1em;
    }

    @media screen and (max-width: 900px) {
        padding: 10px;
        margin: 10px 0;

        h3 {
            font-size: 1em;
        }

        p {
            font-size: 0.7em;
        }
    }
}

.data_card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 2%;
    width: 135%;
    margin-left: -15%;
    padding: 20px;
    border-radius: 9px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    transition: transform 0.5s;
    transform: scale(0.5);

    .rating {
        display: flex;
        align-items: center;

        p {
            margin: 0;
            font-size: 18px;
            margin-left: 15px;
            font-weight: 500;
            display: flex;
            align-items: center;

            #VerifiedIcon {
                color: rgb(26, 198, 241);
                font-size: 24px;
                margin-right: 4px;
            }
        }

        .stars {
            display: flex;
            align-items: center;

            h5 {
                font-weight: 400;
                color: #0e0d0d;
                margin-left: 5px;
            }

            #star {
                color: #FFC107;
                margin-left: 2px;
                font-size: 22px;
            }
        }
    }

    h2 {
        font-size: 20px;
        color: #202d41;
        letter-spacing: 0.3px;
        align-self: start;
    }

    p {
        text-align: justify;
        line-height: 25px;
        font-size: 15px;
        color: grey;
    }

    h3 {
        font-size: 17px;
        color: #202d41;
        letter-spacing: 0.3px;
        align-self: start;
        margin-top: 6px;
    }

    @media screen and (max-width: 900px) {
        padding: 10px;
        padding-bottom: 30px;
        width: 100%;
        margin-left: 0;

        .rating {
            .stars {
                #star {
                    font-size: 20px;
                }
            }

            p {
                font-size: 15px;

                #VerifiedIcon {
                    font-size: 20px;
                    margin-right: 4px;
                }
            }
        }

        h2 {
            font-size: 18px;
        }

        p {
            font-size: 14px;
        }
    }
}

.slick-center .data_card {
    transform: scale(1.02);
}


@media screen and (max-width:900px) {}