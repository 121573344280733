/* / Navigation styling   */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5% 10px 5%;
    background-color: #F9F5F1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    z-index: 100;
}

#navbarLogo {
    height: auto;
    width: 160px;
    cursor: pointer;
    transition: filter .3s ease, transform .3s ease, color .3s ease;
}

#navbarLogo:hover {
    filter: drop-shadow(0px 0px 4px #ffffff);
    transform: translateX(10px);
}

#navbarMenu {
    display: flex;
    align-items: center;
}

#navbarMenu a {
    margin: 0px 30px;
    font-size: 18px;
    font-weight: 300;
    color: black;
    text-decoration: none;
    transition: color .1s ease;
    cursor: pointer;
}

.nav-login-btn{
    color: #FFF !important;
}

#navbarMenu p {
    font-size: 40px;
    font-weight: 200;
    color: lightgray;
}

#navbarMenu a:hover {
    color: #00a1e4;
}

#LoginPageButton {
    border-radius: 20px;
    background-color: #00a1e4;
    font-size: 15px !important;
    padding: 8px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}





#LoginPageButton:hover {
    color: white !important;
}

#userName {
    border-radius: 20px;
    width: auto;
    padding: 8px 20px;
    background-color: #00a1e4;
    color: #FFF;
}

/* / This Useroptions is for showing the sub menu / */
.UserOptions {
    display: flex;
    align-items: center;
    position: relative;
}

#UserOptionsMenu {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    border-radius: 14px;
    background-color: white;
    z-index: 10;
    left: -10px;
    display: none;
    transition: .4s ease all;
    bottom: -80px;
    padding: 7px;
}

#UserOptionsMenu li {
    list-style: none;
    text-align: left;
    padding: 7px 10px 7px 10px;
    font-size: 16px;
    font-weight: 200;
    display: flex;
    justify-content: space-between;
}

#navbarMenu a:hover #UserOptionsMenu li {
    color: black;
}

#UserOptionsMenu li:hover {
    color: #eb2d18 !important;
}

.UserOptions:hover #UserOptionsMenu {
    display: block;
    animation-name: showUserOptionsMenu;
    animation-duration: .3s;
}

@keyframes showUserOptionsMenu {
    0% {
        bottom: -180px;
        opacity: 0;
    }

    100% {
        bottom: -80px;
        opacity: 1;
    }
}


@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.stickyNavbar {
    position: fixed;
    top: 0;
    width: 100%;
    animation-duration: 0.4s;
    animation-name: fadeInDown;
    animation-timing-function: ease-out;
}



/* / This styling is for the SIDENAV when we enter in the mobile mode  / */

#SideNavButton {
    height: 50px;
    margin: 0px 30px;
    font-size: 30px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.979);
    transition: color .1s ease;
    cursor: pointer;
    display: none;
}

#SideNavButton:hover {
    /* color: #eb2d18; */
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 60;
    top: 60px;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10%;
    text-align: start;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-width:750px) {
    .navbar {
        padding: 10px 1% 8px 3%;
    }

    #navbarLogo {
        width: 120px;
    }

    #navbarMenu a {
        margin: 0px 20px;
        font-size: 16px;
    }

    #LoginPageButton {
        margin: 2% 5%;
        width: 50%;
        color: white;
    }
}

@media screen and (max-width:600px) {
    #navbarMenu {
        display: none;
    }

    .nav-abc {
        display: none;
    }

    #SideNavButton {
        width: 40px;
        height: 40px;
        display: flex;
        color: #111;
        justify-content: center;
        align-items: center;
        border: 2px solid gray;
        border-radius: 50%;
        font-size: 20px;
        padding: 3px;
    }

    #LoginPageButton {
        margin: 2% 7%;
        width: 50%;
        color: white;
    }

}

@media screen and (max-width: 450px) {
    .sidenav {
        padding-top: 15%;
    }

    .sidenav a {
        font-size: 20px;
    }

    #LoginPageButton {
        margin: 2% 7%;
        width: 50%;
        color: white;
    }
}