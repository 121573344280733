.WhyDigiesim {
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-color: #3CADF9;
    padding: 70px 0;

    h2 {
        font-size: 36px;
        color: white;
        font-weight: 700;
        text-align: center;
    }

    .img-section {
        display: grid;
        grid-template-columns: repeat(4, 21%);
        justify-content: space-evenly;
        align-items: center;
        margin-top: 15px;
        gap: 10px;

        .WhyCard {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 10px;

            img {
                width: 70px;
                height: 70px;
            }

            h2 {
                font-size: 25px;
            }

            p {
                font-size: 15px;
                color: white;
            }
        }
    }

    /* Tablet view */
    @media screen and (max-width: 800px) {
        h2 {
            font-size: 33px;
        }

        .img-section {
            grid-template-columns: repeat(2, 40%);
            margin-top: 15px;
            gap: 5%;

            .WhyCard {
                h2 {
                    font-size: 26px;
                }

                p {
                    font-size: 16px;
                    width: 250px;
                }
            }
        }
    }

    /* Mobile view (2 divs per row) */
    @media screen and (max-width: 550px) {
        h2 {
            font-size: 30px;
        }

        .img-section {
            grid-template-columns: repeat(2, 48%);
            margin-top: 10px;
            gap: 6%;

            .WhyCard {
                gap: 7px;

                img {
                    width: 60px;
                    height: 60px;
                }

                h2 {
                    font-size: 23px;
                }

                p {
                    font-size: 15px;
                    width: 200px;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .img-section {
            grid-template-columns: repeat(2, 42%);

            .WhyCard {
                transform: scale(.9);

                img {
                    width: 50px;
                    height: 50px;
                }

                h2 {
                    font-size: 20px;
                }

                p {
                    font-size: 13px;
                    width: 170px;
                }
            }
        }
    }
}