.PaymentSuccess {
    width: 100%;
    height: 89vh;
    background-image: url(../../Assets/carddigi.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.SuccessPaymentModalStyling {
    width: 60%;
    height: 70%;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .success_gif {
        height: auto;
        width: 40%;
    }


    .paymnet_main {
        width: 50%;


        .payment_detail {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px 0px;
            line-height: 1.8;
            text-align: justify;

            h2 {
                font-size: 28px;
                letter-spacing: 1px;
                color: black;
                /* text-align: center; */
            }

            .SuccessIcon {
                height: 35px;
                width: 35px;
                color: green;
                margin-left: 20px;
            }

            p {
                display: flex;
                padding: 5px 0;
                font-weight: 500;
                color: black;

                span {
                    margin-left: 10px;
                    text-align: center;
                    color: black;
                    font-weight: 400;
                }
            }
        }
    }


}


@media screen and (max-width: 900px) {
    .SuccessPaymentModalStyling {
        width: 90%;
        height: auto;
        padding: 10px;

        .success_gif {
            /* display: none; */
        }

        .paymnet_main {
            width: 100%;

            .payment_detail {
                width: 100%;

                h2 {
                    font-size: 22px;
                }

                p {
                    align-items: center;
                    padding: 10px 0;

                    span {
                        margin-left: 0;
                        text-align: center;
                    }
                }
            }
        }
    }

}


@media screen and (max-width: 570px) {
    .SuccessPaymentModalStyling {
        width: 90%;
        height: auto;
        padding: 20px;


        .success_gif {
            display: none;
        }

        .paymnet_main {
            width: 100%;

            .payment_detail {
                position: relative;
                width: 100%;

                h2 {
                    font-size: 28px;
                }

                .SuccessIcon {
                    height: 35px;
                    width: 35px;
                    color: green;
                    margin-left: 10px;
                }

                p {
                    font-size: 16px;
                    align-items: center;
                    padding: 10px 0;

                    span {
                        font-size: 14px;
                        margin-left: 0;
                        text-align: center;
                    }
                }
            }

            .payment_detail::before {
                content: "";
                /* background-image: url(../../Assets/payment_success.gif); */
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                opacity: 0.3;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

        }
    }

}