.FamousCountries {
    padding: 0px 0 10px;
    width: 90%;
    margin: 0 auto;
    min-height: 40vh;
    text-align: center;

    h1,
    h2,
    h3 {
        color: #292B2E;
        letter-spacing: .7px;
    }

    h1 {
        font-size: 2.5rem;
        font-weight: 500;
        margin: 40px 0 10px;
    }

    h2 {
        font-size: 2rem;
        font-weight: 400;
        margin: 10px 0 40px;
    }

    h3 {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;

        .one_liner {
            font-size: 22px;
        }
    }

    .heading-text {
        width: 100%;
        font-size: 28px;
        font-weight: 500;
        color: #292B2E;
        text-align: center;
    }

    .serach_bar {
        padding: 10px;
        width: 40%;
        margin-bottom: 20px;
        border-radius: 10px;
        border: 2px solid #4d4a4a;
        background-color: #FFF;
        color: black;
        font-size: 18px;
        text-align: center;
    }

    .AllCountries {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
        margin-top: 20px;
    }

    .CountryNameCard {
        /* flex: 1 1 23%; */
        width: 23%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
        border-radius: 7px;
        cursor: pointer;
        transition: box-shadow 0.3s ease;
    }

    .CountryNameCard:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }

    .CountryNameCard h3 {
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 0.5px;
    }

    #Flag {
        width: 45px;
        border-radius: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 12px 18px;
    }
}

/* Responsive Styles */

@media screen and (max-width: 1200px) {
    .FamousCountries {

        .CountryNameCard {
            flex: 1 1 30%;


            .serach_bar {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .FamousCountries {
        h1 {
            font-size: 1.8rem;
            margin: 30px 0 10px;
        }

        h2 {
            font-size: 1.5rem;
            margin: 10px 0 30px;
        }

        .serach_bar {
            width: 60%;
        }

        .CountryNameCard {
            flex: 1 1 45%;
        }
    }
}

@media screen and (max-width: 628px) {
    .FamousCountries {
        padding-top: 20px;

        h1 {
            font-size: 28px;
            margin: 20px 0 8px;
            display: none;
        }

        h2 {
            font-size: 20px;
            display: none;
            /* margin: 8px 0 20px; */
        }

        h3 {
            font-size: 28px;
            line-height: 1.3;

            .one_liner {
                font-weight: 400;
                font-size: 20px;
            }
        }

        .serach_bar {
            width: 90%;
            text-align: center;
            margin-top: 10px;
        }

        .CountryNameCard {
            flex: 1 1 100%;
        }
    }
}