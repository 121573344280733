.CountryInRegion {
    padding: 30px 0% 10px 0%;
    width: 90%;
    margin: 10px auto;
    min-height: 40vh;

    h1 {
        font-size: 30px;
        margin: 20px 0px;
        font-weight: 500;
        color: #292B2E;
    }

    .MainSection {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .ImageSection {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-top: 25px;

            img {
                width: 80%;
                border-radius: 10px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                height: auto;
            }
        }

        .InfoSection {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            width: 50%;

            h2 {
                font-size: 30px;
                font-weight: 500;

                span {
                    color: #1c8ebf;
                }
            }

            .RegionCard {
                width: 45%;
                display: flex;
                align-items: center;
                padding: 8px;
                justify-content: space-between;
                box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
                border-radius: 5px;
                cursor: pointer;
                background: linear-gradient(105deg, #5CA7E0 69%, #75BFEC 69%);
                transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

                .Names {
                    display: flex;
                    flex-direction: column;
                    /* row-gap: 10px; */
                    text-align: center;

                    h4 {
                        font-size: 1.55rem;
                        letter-spacing: 0.4px;
                        color: #042941;
                        font-weight: 500;
                    }

                    p {
                        color: #291717;
                        background-color: #FFF;
                        padding: 0 3px 0 3px;
                        font-size: 0.75rem;
                        border-radius: 7px;
                        /* width: fit-content; */
                        transition: color 0.3s ease;

                        &:hover {
                            color: rgb(57, 138, 245);
                        }
                    }
                }

                &:hover {
                    transform: translateY(-10px);
                    box-shadow: 0 0 10px rgb(75, 71, 71);
                }
            }

            p {
                font-size: 17px;
                font-weight: 400;
                color: #292B2E;
                line-height: 25px;
            }

            .CountryList {
                display: grid;
                grid-template-columns: auto auto;
                flex-wrap: wrap;
                align-items: center;
                gap: 20px;

                .CountryData {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    #Flag {
                        margin: 0px 10px;
                        width: 40px;
                        height: 25px;
                        object-fit: fill;
                        border-radius: 3px;
                        box-shadow: 0px 0px 1px rgb(87, 117, 151);
                    }

                    #Name {
                        font-size: 17px;
                        color: #0f1e30;
                    }
                }


                .CountryData:hover {
                    transform: translateX(10px);
                    transition: all .4s ease-in-out;

                    #Name {
                        color: #5090d8;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1400px) {

    .CountryInRegion {
        .MainSection {

            .InfoSection {

                .RegionCard {
                    width: 80%;
                }
            }
        }

    }
}


@media screen and (max-width: 1000px) {

    .CountryInRegion {
        .MainSection {
            .ImageSection {
                img {
                    width: 85%;
                }
            }

            .InfoSection {
                row-gap: 13px;

                h2 {
                    font-size: 25px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    text-align: justify;
                }

                .RegionCard {
                    width: 80%;
                }


                .CountryList {
                    gap: 10px;

                    .CountryData {
                        transform: scale(.9);
                    }
                }
            }
        }

    }
}



@media screen and (max-width: 700px) {

    .CountryInRegion {
        .MainSection {
            flex-direction: column;

            .ImageSection {
                margin-top: 0px;
                width: 100%;
                padding-bottom: 30px;

                img {
                    width: 50%;
                }
            }

            .InfoSection {
                width: 100%;
                row-gap: 17px;

                h2 {
                    font-size: 26px;
                }

                p {
                    font-size: 16px;
                    line-height: 25px;
                    text-align: justify;
                }


                .RegionCard {
                    width: 60%;
                }


                .CountryList {
                    gap: 10px;
                    justify-content: space-between;

                    .CountryData {

                        #Flag {
                            margin: 0px 7px;
                            width: 35px;
                            height: 20px;
                        }

                        #Name {
                            font-size: 15px;
                            color: #0f1e30;
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 450px) {

    .CountryInRegion {

        h1 {
            font-size: 23px;
            text-align: center;
        }

        .MainSection {
            .ImageSection {
                justify-content: flex-start;
                padding-bottom: 20px;

                img {
                    width: 100%;
                }
            }



            .InfoSection {
                width: 100%;
                row-gap: 17px;
                row-gap: 10px;

                h2 {
                    font-size: 20px;
                }

                p {
                    font-size: 15px;
                    line-height: 22px;
                    text-align: justify;
                    margin: 5px 0px;
                }

                .RegionCard {
                    width: 80%;
                    padding: 3px;

                    .Names {
                        h4 {
                            font-size: 1.4rem;
                        }
                    }
                }


                .CountryList {
                    margin-top: 10px;
                    gap: 10px;
                    justify-content: space-between;
                    padding: 0px 5px;

                    .CountryData {

                        #Flag {
                            margin: 0px;
                            margin-right: 7px;
                            width: 35px;
                            height: 20px;
                        }

                        #Name {
                            font-size: 15px;
                            color: #0f1e30;
                        }
                    }
                }
            }
        }
    }
}