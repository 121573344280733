.FamousRegions {
    width: 90%;
    margin: 15px auto;
    text-align: center;

    h3 {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
        color: #292B2E;
        letter-spacing: .7px;

        .one_liner {
            font-size: 22px;
        }
    }

    .AllRegions {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 30px;
        margin-top: 20px;

        .RegionCard {
            display: flex;
            align-items: center;
            padding: 10px;
            justify-content: space-between;
            box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
            border-radius: 5px;
            cursor: pointer;
            background: linear-gradient(105deg, #5CA7E0 69%, #75BFEC 69%);
            transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

            .Names {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                h4 {
                    font-size: 1.25rem;
                    letter-spacing: 0.4px;
                    color: #042941;
                    font-weight: 700;
                    text-align: center;
                }

                p {
                    color: #291717;
                    background-color: #FFF;
                    padding: 3px 8px;
                    font-size: 0.75rem;
                    border-radius: 7px;
                    width: fit-content;
                    transition: color 0.3s ease;

                    &:hover {
                        color: rgb(57, 138, 245);
                    }
                }
            }

            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 0 10px rgb(75, 71, 71);
            }
        }
    }
}

/* Enhanced Responsive Design */
@media screen and (max-width: 768px) {
    /* .FamousRegions {} */

    .AllRegions {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 580px) {
    .FamousRegions {

        h3 {
            font-size: 28px;
            line-height: 1.1;

            .one_liner {
                font-weight: 400;
                font-size: 20px;
            }
        }

        .AllRegions {
            grid-template-columns: repeat(1 1fr);
            gap: 10px;

            .RegionCard {
                padding: 12px 10px;
                margin-bottom: 5px;

                .Names {
                    width: 80%;
                    align-items: center;
                    row-gap: 7px;

                    h4 {
                        font-size: 1.45rem;
                    }

                    p {
                        font-size: .9rem;
                    }
                }
            }

        }
    }
}