.AppSection {
    width: 90%;
    margin: 40px auto;
    min-height: 40vh;
    /* border: 3px orange solid; */

    h2 {
        color: #292B2E;
        margin: 15px 0;
        text-align: center;
    }

    /* Static content for desktop */
    .static-desktop {
        display: flex;
        justify-content: space-around;
    }

    /* Slider for mobile */
    .slider-mobile {
        display: none;
    }

    /* Shared styles for both desktop and mobile sliders */
    .PhonesBox {
        margin-top: 30px;

        .slick-slider {
            width: 100%;
        }

        .Phone {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            width: 20%;

            img {
                height: auto;
                width: 100%;
            }
        }
    }

    /* Mobile view: Show slider and hide static content */

    @media screen and (max-width: 600px) {

        h2 {
            margin: 12px 0;
            font-size: 24px;
            text-align: center;
        }

        /* Hide static content on mobile */
        .static-desktop {
            display: none;
        }

        /* Show slider on mobile */
        .slider-mobile {
            display: block;
        }

        .PhonesBox {
            .Phone {
                width: 100%;
                margin-bottom: 20px;

                img {
                    width: 70%;
                }
            }
        }

    }
}