.card-container {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  flex-direction: column;

  .Payment-sum,
  .Payment-Details {
    width: 100%;
    margin-bottom: 20px;
  }

  .Payment-sum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .card-image {
      width: 40%;
      height: 20%;
      background-color: #FFFFFF;
      /* background-image: url(../../../Assets/carddigi.jpg); */
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      row-gap: 10px;
      padding-top: 20px;
      margin: 0 auto;
      top: 12%;
      right: 10%;
      position: absolute;
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      #img-flag {
        object-fit: cover;
        border-radius: 5px;
        width: 60px;
        height: auto;
        border-radius: 5px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 12px 18px 0px;
      }

      h3 {
        font-size: 26px;
        font-weight: 400;
        text-align: center;
      }

    }

    .card-details {
      width: 100%;
      height: auto;
      background: linear-gradient(90deg, rgb(86, 130, 235) 0px, rgb(34, 67, 145));
      color: white;
      padding: 20px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      line-height: 2;

      .card-info {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        h2 {
          margin-top: 0;
          font-size: 18px;
          font-weight: bold;
          /* margin-top: 10%; */
        }

        .info-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          line-height: 3.5;
        }

        .info-label {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;

          svg {
            margin-right: 5px;
            height: 20px;
            width: 20px;
          }
        }


        .info-value {
          font-size: 14px;
        }
      }
    }
  }

  .Payment-Details {
    width: 100%;
    height: auto;

    .order-sum {
      display: flex;
      flex-direction: column;
      line-height: 2;
      margin-bottom: 20px;

      h2 {
        color: #4a4a4a;
        font-size: 1.3125rem;
        font-weight: 600;
        letter-spacing: -0.3px;
        line-height: 1.1904761;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      p {
        color: #8a8a8a;
        display: inline-block;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1.4;
        margin-top: 10px;
      }
    }

    .order-pri {
      display: flex;
      justify-content: space-between;

      p {
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1.27272;
        max-width: 75%;
        color: #4a4a4a;
      }

      span {
        font-size: 1.0625rem;
        font-weight: 500;
        letter-spacing: -0.1px;
        line-height: 1.17647;
        color: #4a4a4a;
      }
    }

    .payment-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .box1,
      .box2 {
        margin-top: 2%;
        width: 100%;
        cursor: pointer;

        h2 {
          color: #4a4a4a;
          font-size: 1.3125rem;
          font-weight: 600;
          letter-spacing: -0.3px;
          line-height: 1.1904761;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 10px);
        }

        p {
          color: #8a8a8a;
          display: inline-block;
          font-size: 0.9375rem;
          letter-spacing: 0;
          line-height: 1.4;
          margin-top: 10px;
        }

        .card {
          margin-top: 2%;
          background-color: #fff;
          border-radius: 7px;
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
          min-height: 75px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            text-align: start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - 10px);
            margin-top: 1%;
          }
        }
      }
    }

    .checkbox-section {
      display: flex;
      margin: 10px 5px;
      width: 100%;

      div {
        font-size: 0.9375rem;
        text-align: start;
        color: #4a4a4a;
        font-weight: 500;
        text-decoration: underline;
        padding-left: 30px;
        margin-top: -2%;
      }
    }

    .button-section {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}



@media screen and (min-width: 841px) {
  .card-container {
    flex-direction: row;

    .Payment-sum,
    .Payment-Details {
      width: 100%;
    }

    .Payment-sum {
      flex-direction: row;

      .card-image {
        width: 20%;
        height: 220px;
        margin: 0 0 0 -30%;
        position: absolute;
        top: 30%;
        left: 34%;
      }

      .card-details {
        width: 80%;
        height: 350px;

        .card-info {
          margin-left: 40%;
          width: 60%;

        }
      }
    }

    .Payment-Details {
      height: 100%;

      .payment-section {
        flex-direction: row;

        .box1,
        .box2 {
          width: 100%;
        }


      }
    }
  }
}




@media screen and (max-width: 841px) {

  .card-container {
    padding: 5px;

    .Payment-sum {
      margin-top: 7%;
    }


    .Payment-sum {
      display: block;

      .card-image {
        display: none;
        row-gap: 2px;
        margin-top: 2%;

        #img-flag {
          width: 30%;
          margin-top: -5%;
        }

      }

      .card-details {
        width: 100%;
        height: auto;


        .card-info {

          h2 {
            font-size: 18px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 561px) {

  .card-container {
    padding: 5px;

    .Payment-sum {
      margin-top: 15%;
    }

    .Payment-sum {

      .card-image {
        display: none;
        row-gap: 2px;
        margin-top: -2%;

        #img-flag {
          width: 30%;
          margin-top: -5%;
        }

      }

      .card-details {
        width: 100%;
        height: auto;


        .card-info {


          h2 {
            font-size: 15px;
          }

          .info-label {
            font-size: 14px;
          }


        }
      }
    }
  }

}


/* modal start */




.card1 {
  margin-top: 2%;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  min-height: 70px;
  padding: 19px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s;


  p {
    color: #4a4a4a;
    font-size: .8375rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.33333;

  }

  span {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
}

.card1:hover {
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.2);
}



.title-sub {
  color: #4a4a4a;
  font-size: 1.0125rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.1904;
  margin: 0;
  margin-top: 5%;
}

.title-sub-description {
  color: #8a8a8a;
  font-size: .9375rem;
  letter-spacing: 1;
  line-height: 2.4;
  margin: 0;


  span {
    color: #4a4a4a
  }
}

.Apply-sec {
  width: 100%;
  margin-top: 2%;
}

.Apply-code {
  width: 100%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;

  .text_field {
    width: 100%;

  }


  .button-apply {
    width: 40%;
    background-color: #4a4a4a;
    color: #fff;
    text-align: center;
  }


}



.card2 {
  margin-top: 2%;
  background: linear-gradient(90deg, #fbb034, #fc0);
  border-radius: 7px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  min-height: 30px;
  padding: 19px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s;

  p {
    font-size: .8125rem;
    letter-spacing: 0;
    line-height: 1.38461;
    color: #FFF;
    font-weight: 350;
  }
}


.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24;
  padding: 20px;
  width: 650px;
}



@media screen and (max-width: 680px) {
  .modal-box {
    width: 90%;
    margin: 0 auto;
    padding: 10px;

    .modal-header {
      align-items: flex-start;

      h3 {
        margin-bottom: 10px;
      }
    }

    .card1 {
      align-items: flex-start;

      p {
        margin-bottom: 10px;
      }

      Button {
        width: 15%;
      }
    }
  }
}