/* /////////   forgot PAGE STYLING   //////////////////////////////// */

#main {
    width: 100%;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    background-image: url('../../Assets/LoginBg.png');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;


    .BoxTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 30px;
        background-color: white;
        box-shadow: rgba(60, 59, 59, 0.1) -4px 9px 25px -6px;
        z-index: 10;
        height: 15%;


        #ForgotPasswordLogo {
            height: auto;
            width: 180px;
            margin: 15px 0px;
            filter: drop-shadow(0px 0px 6px #AFE4F4);
            cursor: pointer;
        }
    }


    .card_section {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;


        #forgot_card {
            padding: 20px;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 60%;

            #forgot_imgBox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 43%;

                #forgot_img {
                    border-radius: 10px;
                    width: 100%;
                    height: auto;
                }
            }


            #forgot_form {
                background-color: transparent;
                width: 48%;


                .otp-input {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;


                    #forgetInputs {
                        width: 100%;
                        margin-bottom: 15px;
                        height: 45px;
                    }

                    .new-pass {
                        position: relative;
                        width: 100%;
                        padding-bottom: 5px;
                    }
                }

                .otp-email {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    #forgetIn {
                        width: 100%;
                        margin-bottom: 15px;
                        height: 45px;
                    }

                    .get-otp {
                        font-size: 15px;
                        width: 30%;
                        color: #FFFFFF;
                        border: 1px solid #011015;
                        background-color: #8ecae6;
                    }

                    .go-back {
                        font-size: 15px;
                        color: #8ecae6;
                        border: 1px solid #8ecae6;
                        width: 30%;
                        margin-top: 20px;
                        background-color: #FFFFFF;
                    }
                }
            }
        }

    }
}



@media screen and (max-width: 720px) {

    #main {
        width: 100%;

        .card_section {
            flex-direction: column;
            padding: 10px;
            width: 100%;

            #forgot_card {
                flex-direction: column;
                height: auto;
                width: 100%;


                #forgot_imgBox {
                    width: 100%;
                    margin-bottom: 20px;
                }

                #forgot_form {
                    width: 100%;

                    .otp-input {
                        width: 100%;
                    }

                    .otp-email {
                        width: 100%;

                        .get-otp {
                            width: 50%;
                        }

                        .go-back {
                            width: 50%;
                        }
                    }

                    #forgetInputs {
                        height: 40px;
                    }

                }
            }


        }




    }



}




#forgot_data {
    border-radius: 10px !important;
}



input {
    width: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
    border: 1px grey solid;
    border-radius: 4px;
    margin-top: 4px;
}



/* /////////   forgot PAGE STYLING ENDS  //////////////////////////////// */