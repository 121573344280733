.FAQ {
    width: 90%;
    /* border: 2px solid red; */
    margin: 30px auto;

    h2 {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        color: #292B2E;
    }

    .FAQ_container {
        width: 100%;
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-evenly;
        gap: 20px;
        margin: 20px 0px;

        #FAQ_Box {
            transition: background-color 0.3s ease;
            border: 0.5px solid #292B2E;
            margin-top: 0px;
            height: fit-content;
        }

        #FAQ_Box:hover {
            background-color: #F9F5F1;
        }
    }

    .End-section {
        width: fit-content;
        margin: 0 auto;

        button {
            border-radius: 30px;
            height: 45px;
            background-color: #37bce5;
        }

        button:hover {
            background-color: #0b7c9e;
        }
    }
}

@media screen and (max-width:650px) {
    .FAQ {
        h2 {
            font-size: 24px;
        }

        .FAQ_container {
            grid-template-columns: 100%;
        }
    }
}