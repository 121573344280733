.Account {
  width: 90%;
  margin: 20px auto;
  border-radius: 10px;

  h1 {
    font-size: 24px;
    color: #333;
  }

  .profile {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .profile-picture {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #ddd;
      margin: 10px 0;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      .initials {
        font-size: 32px;
        font-weight: bold;
        color: #00a1e4;
      }
    }


    .profile-info {
      display: flex;
      flex-direction: column;
      row-gap: clamp(17px, 2vw, 20px);
      margin-top: 20px;

      h3 {
        font-size: clamp(17px, 2vw, 19px);
        font-weight: 500;
        color: #06384e;

        span {
          font-weight: 400;
          color:black
          
        }
      }
    }
  }

  .css-13xfq8m-MuiTabPanel-root {
    padding: 10px;
  }
}

@media screen and (max-width:700px) {
  .Account {
    width: 92%;
  }

  .profile {
    .profile-info {
      h3 {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        span {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.PlansBox {
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto auto;
  padding-bottom: 20px;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */

  .PlanName {
    background: linear-gradient(145deg, rgba(154, 210, 235, 0.4), rgba(91, 140, 247, 0.4));
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 13px;
    border-radius: 12px;
    min-height: 150px;
    /* width: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    line-height: 1.5;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #656363;
    overflow-y: auto;

    h2 {
      font-size: 1.4rem;
      font-weight: 400;
      color: #0a8ac1;

      span {
        font-size: 1rem;
      }
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      text-shadow: 0px 0px 3px white;
      /* border: 2px solid red; */
      display: flex;


      span {
        font-weight: 400;
        margin-left: 2px;
        margin: 0px 0px 0px 8px;
        /* border: 2px solid blue; */
      }
    }

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
    }
  }
}

#PlanCardAccordian {
  h2 {
    color: #656363;
    font-weight: 600;
    display: flex;
    align-items: center;

    #HashIcon {
      color: #00a1e4;
      margin-right: 3px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 830px) {
  .PlansBox {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 620px) {
  .PlansBox {
    grid-template-columns: auto;
  }
}


/* This accordian is for showing the payment statuses in the payment section */
#Accordian {
  #Accordian_Head {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background: linear-gradient(105deg, #ebe3e3 69%, rgb(91, 187, 227) 69%);

    #Icon {
      color: #00a1e4;
      font-size: 45px;
      filter: drop-shadow(0px 0px 4px white);
      margin-right: 10px;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: #656363;
    }

    p {
      font-size: 15px;
      font-weight: 600;
      color: #333;

      span {
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }

  .Accordion_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      color: #00a1e4;
      font-weight: 600;
      text-decoration: underline;
    }

    p {
      font-size: 16px;
      margin-top: 5px;
      text-transform: capitalize;
      font-weight: 600;

      span {
        font-weight: 400;
      }
    }
  }
}

@keyframes progressBar {
  from {
    width: 0%;
  }

  to {
    width: calc();
  }
}

/* This is for the styling in the update password  */


.updatePassword {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0;
}

.upadtePas {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 15px;
}

.updatebtn {
  background-color: #00a1e4;
  color: white;
  width: 40%;
}

@media screen and (max-width: 768px) {
  .updatePassword {
    width: 80%;
  }

  .upadtePas {
    width: 80%;
  }

  .updatebtn {
    width: 80%;
  }

}

@media screen and (max-width: 488px) {
  .updatePassword {
    width: 100%;
  }

  .upadtePas {
    width: 100%;
  }

  .updatebtn {
    width: 100%;
  }
}