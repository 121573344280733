.SelectedCountryPlan {
    padding: 30px 0 10px;
    width: 80%;
    margin: 10px auto;
    min-height: 40vh;

    .one {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        #SelectedCountryFlag {
            width: 60px;
            margin: 0 10px;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
        }

        h1 {
            font-size: 28px;
            font-weight: 500;
            color: grey;
            margin: 0 10px;
            letter-spacing: 1px;
        }

        p {
            color: rgb(172, 167, 167);
            font-size: 18px;
            letter-spacing: 0.5px;
            margin-top: 13px;
            text-align: center;
        }
    }

    .two {
        margin-top: 20px;

        #tabs {
            display: flex;
            justify-content: center;
        }

        .css-13xfq8m-MuiTabPanel-root {
            padding: 15px;
        }


        .tabPanel {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
            background-color: #F0F0EE;
            display: grid;
            grid-template-columns: 32% 32% 32%;
            row-gap: 15px;
            justify-content: space-between;
        }

        .Card {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .Card1 {
                display: flex;
                width: 100%;

                h1 {
                    background-color: #E7EB14;
                    border-radius: 10px 10px 10px 0px;
                    color: black;
                    padding: 10px 30px;
                    font-size: 20px;
                    transform: translateY(10px);
                    text-align: center;
                }
            }


            .Card2 {
                background: linear-gradient(105deg, #5CA7E0 69%, #75BFEC 69%);
                color: white;
                display: flex;
                padding: 7px 12px;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 70px;
                border-top-right-radius: 22px;

                #Card1Flag {
                    width: 40px;
                    border-radius: 3px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
                }

                h2 {
                    color: white;
                    font-size: 22px;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 40%;
                    text-align: center;
                }

                h3 {
                    color: white;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .Card3 {
                background: white;
                border-radius: 0 0 20px 20px;
                font-weight: 700;
                line-height: 2;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 12px;

                .features {
                    display: flex;
                    flex-direction: column;
                    row-gap: 7px;
                    width: fit-content;
                    margin: auto;

                    .feature-item {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: .4px;

                        svg {
                            font-size: 24px;
                            color: #5CA7E0;
                        }
                    }
                }

                #PriceBox {
                    display: flex;
                    justify-content: center;
                    width: 90%;
                    margin: 10px auto 0px;

                    .button1 {
                        font-size: 22px;
                        font-weight: 700;
                        color: black;
                        background-color: #D6F0F6;
                        border-radius: 10px 0 0 10px;
                        padding: 6px 0px;
                        width: 30%;
                        text-align: center;
                    }

                    .button2 {
                        font-size: 22px;
                        font-weight: 700;
                        color: white;
                        background-color: #5CA7E0;
                        border-radius: 0 10px 10px 0;
                        padding: 6px 0px;
                        width: 68%;
                        text-transform: capitalize;
                        text-align: center;
                    }
                }

                .end-sec {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 90%;
                    margin: 0px auto;

                    h2 {
                        display: flex;
                        color: black;

                        .sim_icon {
                            color: #5CA7E0;
                            border-radius: 50px;
                            font-size: 28px;
                        }

                        span {
                            font-size: 15px;
                            font-weight: 400;
                            margin-left: 3px;
                        }
                    }

                    h1 {
                        font-size: 35px;
                        color: #5CA7E0;
                        font-weight: 800;

                        #networkLabel {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }
}

/* Responsive CSS */

@media screen and (max-width:1000px) {
    .SelectedCountryPlan {
        padding: 10px 0 10px;
        width: 90%;
        margin: 0px auto;

        .two {
            margin-top: 20px;

            .tabPanel {
                grid-template-columns: 32% 32% 32%;
                row-gap: 15px;
            }

            .Card {
                .Card1 {
                    h1 {
                        padding: 8px 22px;
                        font-weight: 500;
                        font-size: 16px;
                        transform: translateY(12px);
                    }
                }

                .Card2 {
                    padding: 7px 14px;
                    height: 60px;
                    border-top-right-radius: 22px;

                    #Card1Flag {
                        width: 40px;
                    }

                    h2 {
                        font-size: 18px;
                    }

                    h3 {
                        font-size: 20px;
                    }
                }

                .Card3 {
                    line-height: 2;

                    .features {
                        row-gap: 5px;

                        .feature-item {
                            font-size: 14px;
                            letter-spacing: .2px;

                            svg {
                                font-size: 20px;
                            }
                        }
                    }

                    #PriceBox {
                        width: 90%;

                        .button1 {
                            font-size: 20px;
                            width: 34%;
                        }

                        .button2 {
                            font-size: 19px;
                            width: 66%;
                        }
                    }

                    .end-sec {
                        width: 92%;

                        h2 {
                            display: flex;
                            color: black;

                            .sim_icon {
                                font-size: 25px;
                            }

                            span {
                                font-size: 14px;
                            }
                        }

                        h1 {
                            font-size: 30px;

                            #networkLabel {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width:780px) {
    .SelectedCountryPlan {
        padding: 10px 0 10px;
        width: 95%;
        margin: 0px auto;

        .one {
            width: 80%;
            margin: 0px auto;
            margin-top: 30px;

            #SelectedCountryFlag {
                width: 50px;
            }

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 15px;
                margin-top: 13px;
            }
        }

        .two {
            margin-top: 20px;

            .css-13xfq8m-MuiTabPanel-root {
                padding: 12px 10px;
            }


            .tabPanel {
                grid-template-columns: 48% 48%;
            }

            .Card {
                .Card1 {
                    h1 {
                        padding: 8px 20px;
                        font-weight: 500;
                        font-size: 16px;
                        transform: translateY(10px);
                    }
                }

                .Card2 {
                    padding: 7px 12px;
                    min-height: 50px;

                    #Card1Flag {
                        width: 37px;
                    }
                }

                .Card3 {
                    line-height: 1.7;

                    .features {
                        display: grid;
                        width: 90%;
                        margin: 5px auto;
                        grid-template-columns: auto auto;
                        row-gap: 10px;

                        .feature-item {
                            font-size: 13px;

                            svg {
                                font-size: 18px;
                            }
                        }
                    }

                    #PriceBox {
                        width: 80%;

                        .button1 {
                            font-size: 17px;
                            width: 35%;
                        }

                        .button2 {
                            font-size: 17px;
                            width: 66%;
                        }
                    }

                    .end-sec {
                        width: 90%;

                        h2 {

                            .sim_icon {
                                font-size: 21px;
                            }

                            span {
                                font-size: 12px;
                            }
                        }

                        h1 {
                            font-size: 27px;

                            #networkLabel {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:690px) {
    .SelectedCountryPlan {

        .two {

            .tabPanel {
                grid-template-columns: 48% 48%;
                justify-content: space-between;
            }

            .Card {
                .Card3 {
                    line-height: 1.7;

                    .features {
                        width: 90%;
                        grid-template-columns: auto;
                        row-gap: 10px;
                        margin: 5px auto;
                        justify-content: center;

                        .feature-item {
                            font-size: 15px;

                            svg {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width:520px) {
    .SelectedCountryPlan {
        width: 95%;
        display: flex;
        flex-direction: column;
        height: 100vh;
        /* Ensure it takes full height */
        overflow: auto;

        .one {
            width: 90%;
            margin-top: 20px;


            #SelectedCountryFlag {
                width: 40px;
                margin: 0px 3px;
            }

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }
        }

        .two {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: auto;



            #tabs {
                position: sticky;
                top: 0;
                background-color: white;
                color: red;

                /* Ensure the tabs are visible while scrolling */
                z-index: 10;


            }

            .css-13xfq8m-MuiTabPanel-root {
                padding: 10px 5px 20px 5px;
                overflow-y: auto;
                /* Enable scrolling for content */
                flex: 1;
                /* Allow it to grow and take the remaining height */
            }


            .tabPanel {
                grid-template-columns: 85%;
                justify-content: center;
            }

            .Card {
                .Card1 {
                    h1 {
                        padding: 7px 25px;
                        font-size: 18px;
                    }
                }

                .Card2 {
                    padding: 6px 18px;
                    min-height: 60px;

                    #Card1Flag {
                        width: 40px;
                    }

                    h2 {
                        font-size: 21px;
                    }

                    h3 {
                        font-size: 23px;
                    }
                }

                .Card3 {
                    line-height: 1.7;

                    .features {
                        width: 90%;
                        grid-template-columns: auto;
                        row-gap: 10px;
                        margin: 5px auto;
                        justify-content: center;

                        .feature-item {
                            font-size: 15px;

                            svg {
                                font-size: 18px;
                            }
                        }
                    }

                    #PriceBox {
                        width: 90%;

                        .button1 {
                            font-size: 18px;
                            width: 35%;
                            padding: 5px 0px;
                        }

                        .button2 {
                            font-size: 19px;
                            padding: 4px 0px;
                            width: 66%;
                        }
                    }

                    .end-sec {
                        width: 90%;
                        margin: 5px auto;

                        h2 {

                            .sim_icon {
                                font-size: 23px;
                            }

                            span {
                                font-size: 14px;
                                margin-left: 4px;
                            }
                        }

                        h1 {
                            font-size: 28px;    

                            #networkLabel {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 380px) {
    #tabs .MuiTab-root {
        font-size: 12px;
        /* Reduce font size */
        padding: 0 8px;
        /* Reduce padding */
    }

    #tabs {
        /* overflow-x: auto; */
        /* Enable horizontal scroll */
        white-space: nowrap;
        /* Prevent wrapping */
    }

    .MuiTabScrollButton-root {
        display: flex;
        /* Ensure scroll buttons are visible */
    }
}

@media screen and (max-width: 320px) {
    #tabs .MuiTab-root {
        font-size: 12px;
        /* Reduce font size */
        padding: 0 8px;
        /* Reduce padding */
    }

    #tabs {
        /* overflow-x: auto; */
        /* Enable horizontal scroll */
        white-space: nowrap;
        /* Prevent wrapping */
    }

    .MuiTabScrollButton-root {
        display: flex;
        /* Ensure scroll buttons are visible */
    }
}


@media screen and (max-width:300px) {
    .SelectedCountryPlan {
        width: 85%;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        .one {
            width: 93%;

            #SelectedCountryFlag {
                width: 35px;
                margin: 0px 2px;
            }

            h1 {
                font-size: 19px;
            }

            p {
                font-size: 13px;
            }
        }

    }
}


.What_Included {
    display: flex;
    align-items: center;
    cursor: pointer;

    .Icon {
        margin-right: 8px;

    }

    span {
        color: indianred;
    }
}

.What_Included:hover {
    transform: translateX(10px);
    transition: all .4s ease-in-out;
    color: rgb(252, 79, 79);

    .Icon {
        color: indianred;
    }

    span {
        color: rgb(252, 79, 79);
        text-decoration: underline;
    }
}





.redirect_modal {
    width: 30%;
    height: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2;

    h2 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    p {
        display: flex;
        padding: 5px 0;
        font-weight: 500;
        text-align: center;
    }

}


@media screen and (max-width: 880px) {

    .redirect_modal {
        width: 70%;
        height: auto;

    }

}


@media screen and (max-width: 580px) {

    .redirect_modal {
        width: 80%;
        height: auto;

    }

}



.IncludedModal {
    display: flex;
    justify-content: space-around;
    background-color: #FFF;
    color: black;



    .box1 {
        width: 100%;
        height: auto;

        h2 {
            text-align: start;
            font-weight: 500;
        }

        #info {
            display: flex;
            font-size: 18px;
            line-height: 1.5;

            .icon {
                color: #5CA7E0;
                margin-right: 8px;
                font-size: 25px;
            }

            a {
                font-size: 18px;
                cursor: pointer;
                color: black;
                text-decoration: none;

                span {
                    font-size: 18px;
                    text-decoration: underline;
                    font-weight: 500;
                }
            }
        }
    }
}

.Purchase_iccidModal {
    position: relative;
    width: 40%;
    height: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2;

    h2 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    p {
        /* display: flex; */
        padding: 5px 0;
        font-weight: 400;
        text-align: left;
        font-size: 20px;
    }

    .Box_1 {
        display: flex;
        width: 88%;
        /* border: 2px solid red; */

        .Autocomplete {
            display: flex;
            width: 75%;
            margin-top: 10px;

        }

        .selectbtn {
            width: 25%;
            margin-top: 10px;
        }
    }

    .Proceedbtn {
        align-items: flex-start;
        width: 65%;
        /* border: 8px solid red; */
    }
}

@media screen and (max-width: 1000px) {
    .Purchase_iccidModal {
        width: 70%;

        p {
            text-align: left;
        }

        .Box_1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            /* border: 2px solid red; */

            .Autocomplete {
                width: 100%;
                /* border: 2px solid red; */
            }

            .selectbtn {
                width: 65%;
                margin: 10px 0px;
                transform: translate(-50%, 50%);
                top: 50%;
                left: 50%;
            }
        }
    }
}

@media screen and (max-width: 660px) {

    .Purchase_iccidModal {
        width: 85%;
        /* border: 2px solid red; */

        p {
            font-size: 17px !important;
            text-align: left;
        }

    }

}