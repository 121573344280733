* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Figtree", sans-serif;
  transition: .3s;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: lightgrey;
}

*::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* This is will give fadeindown Animation to everything on the website  */
h1,
h1,
h3,
h4,
p,
a,
li,
img {
  animation-name: AllFadeUp;
  animation-duration: .6s;
  letter-spacing: 0.2px !important;
}


@keyframes AllFadeUp {
  0% {
    transform: translateY(6px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


@keyframes Loader {
  0% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(.8);
  }
}



.MaintenanceModePopUp {
  color: rgb(238, 96, 96);
  background-color: white;
  width: 90%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  background-color: #00A1E4;
  /* background-image: linear-gradient(to bottom, #24d2e2, #378cdd, #b89cfb, #d9ceff, #ffffff); */
  /* background-image: linear-gradient(to right top, #0a51f0, #0e59eb, #1a60e6, #2767e0, #346dd9, #567ada, #6f87da, #8494db, #a8ade4, #c7c7ec, #e4e2f6, #ffffff); */
  padding: 15px 0px;


  h2 {
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 50px;
    font-size: 30px;
    text-align: center;

    span {
      font-size: 45px;
    }
  }

  img {
    width: 300px;
    height: auto;
    border-radius: 15px;

  }

  p {
    font-size: 24px;
    letter-spacing: .3px;
    line-height: 25px;
    text-align: center;
    color: #170b23;
    width: 70%;
  }
}

@media screen and (max-width:600px) {
  .MaintenanceModePopUp {
    h2 {
      font-size: 25px;
      font-weight: 700;
      letter-spacing: 1px;

      line-height: 50px;

      span {
        font-size: 30px;
        letter-spacing: 1px;
        text-align: center;

      }
    }

    img {
      width: 250px;

    }

  }
}


@media screen and (max-width:400px) {
  .MaintenanceModePopUp {
    h2 {
      font-size: 20px;
      font-weight: 700;

      span {
        font-size: 20px;
        letter-spacing: 1px;
        text-align: center;

      }
    }

    img {
      width: 200px;

    }

  }
}