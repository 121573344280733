.Footer {
    width: 100%;
    height: fit-content;
    background: #3CADF9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    .footer_top {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        width: 90%;

        .sections {
            display: flex;
            flex-direction: column;
            padding: 20px 0px;

            p {
                color: white;
                margin-top: 15px;
                line-height: 23px;
                font-size: 17px;
                text-align: left;
                /* letter-spacing: .2px; */
            }

            h3 {
                color: whitesmoke;
                font-size: 20px;
                margin: 10px auto 0px auto;
                letter-spacing: .2px;
            }

            a {
                color: white;
                font-size: 17px;
                text-decoration: none;
                margin: 10px auto 0px auto;
                transition: .5s ease all;
            }

            a:hover {
                transform: translateX(6px);
                color: #4b4949;
            }
        }
    }

    .footer_end {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        padding: 10px 10px;
        color: #383030;

        p {
            font-size: 15px;
        }

        h3 {
            margin: 0px 5px;
            font-weight: 500;
        }

        .icons_box {
            display: flex;

            #icon {
                font-size: 22px;
                margin-left: 5px;
                cursor: pointer;
                color: #383030;
            }
        }
    }

    .CreatedBy {
        background-color: #131F32;
        width: 100%;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        padding: 7px 0px;
        gap: 2px;

        p {
            font-size: 15px;
            color: #FFF;
            margin-left: 20px;
            font-weight: 300;
        }

        a {
            text-decoration: none;
            color: #FFF;

            span {
                font-weight: 500;
                font-size: 16px;
                margin: 0px 4px;
            }
        }
    }
}


/* Responsiveness starts here  */

@media screen and (max-width:840px) {
    .Footer {
        .footer_top {
            grid-template-columns: 46% 46%;
            justify-content: space-between;
            width: 90%;
            padding-top: 10px;


            .sections {
                p {
                    text-align: justify;
                }

                h3 {
                    margin: 10px auto 0px 4px;
                    /* letter-spacing: .5px; */
                }

                a {
                    margin: 10px auto 0px 4px;
                }
            }
        }

        .footer_end {
            width: 95%;

            .icons_box {
                display: flex;
                margin-right: 10%;
            }
        }
    }
}

@media screen and (max-width:500px) {
    .Footer {
        .footer_top {
            grid-template-columns: 100%;

            .sections {
                padding: 10px 0px;

                p {
                    width: 100%;
                    font-size: 18px;
                    line-height: 28px;
                    text-align: left;

                    a {
                        font-weight: 500;
                        font-size: 17px;
                    }
                }

                h3 {
                    margin: 0px;
                    letter-spacing: .5px;
                    font-size: 18px;
                }

                a {
                    margin: 12px auto 0px 4px;
                    font-size: 16px;
                }
            }
        }

        .footer_end {
            flex-direction: column-reverse;

            h3 {
                display: none;
            }

            .icons_box {
                display: flex;
                width: 100%;
                margin: 7px;
                justify-content: center;
                column-gap: 5px;
            }
        }
    }
}