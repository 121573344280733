.LoginPage {
    min-height: 100vh;
    width: 100%;
    padding-bottom: 40px;
    background-image: url('../../Assets/LoginBg.png');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.LoginTop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 17%;
}

#LoginPageLogo {
    height: auto;
    width: 200px;
    margin: 15px 0px;
    cursor: pointer;
}

.LoginBottom {
    width: 380px;
    margin: auto;
}

#LoginTabs {
    width: 50%;
    background: rgba(92, 167, 224, .7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    text-align: center;
    padding: 14px 0px;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 2px;
    border-radius: 15px;
    cursor: pointer;
    /* border: 1px rgb(92, 167, 224) solid; */
}

#login_card {
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
    border: 2px lightgrey solid;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
    z-index: 20;
    animation: slide .7s ease-in-out;
    background: rgba(250, 250, 250, 0.7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

#login_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;

}

#login_data input {
    width: 100%;
}

.logos {
    height: 42px;
    width: 42px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 5px;
    cursor: pointer;
}

.ResendOtp {
    color: red;
    background-color: green;
}

@keyframes slide {
    0% {
        display: none;
        scale: .1;
    }

    100% {
        display: block;
        scale: 1;
    }
}

/* Responsiveness starts here  */

@media screen and (max-width:500px) {
    #LoginPageLogo {
        width: 170px;
        margin: 20px 0px;
    }

    .LoginBottom {
        width: 350px;
    }
}

@media screen and (max-width:400px) {
    #LoginPageLogo {
        width: 150px;
    }

    .LoginBottom {
        width: 300px;
    }


    #LoginTabs {
        padding: 14px 0px;
        font-size: 17px;
    }

    #login_card {
        padding: 4px;
    }

}